/*eslint-disable*/
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';

import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function TuringUserValidation({ onValidate }) {

    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");

    // function handleEmailchange(event) {
    //     setEmail(event.target.value);
    // }

    // function handlePasswordchange(event) {
    //     setPassword(event.target.value);
    // }

    const history = useHistory();
    

    const handleValidation = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const email = formJson.email;
        const password = formJson.password;

        if (email === process.env.REACT_APP_ADMIN_EMAIL_ADDRESS && password === process.env.REACT_APP_WEB_PAGE_ACCESS_PASSWORD) {
            localStorage.setItem("validated", "true");
            history.push("/");
            onValidate();
        }
        else {
            alert("Incorrect email or password entered.");
        }
    };

    // return (
    //     <div className="form-container">
    //     <div className="form-sub-container">
    //     <div className="cluster">
    //         <div className="label">
    //             Enter Mail ID:
    //         </div>
    //         <input className="val-inp inp2" type="text"  onChange={handleEmailchange} />
    //     </div>
    //     <div className="cluster">
    //         <div className="label">
    //             Enter Password:
    //         </div>
    //         <input className="val-inp inp1" type="password" onChange={handlePasswordchange} />
    //     </div>
    //     <button className="submit-btn" onClick={handleValidation}>
    //         Access Website
    //     </button>
    //     </div>
    // </div>

    // );

    return (
        <Fragment>
            <Dialog
                open={true}
                
                PaperProps={{
                    component: 'form',
                    onSubmit: handleValidation
                }}
            >
                <DialogTitle>Website Access New</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        
                        margin="dense"
                        id="name"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    />

                    <TextField
                        autoFocus
                        
                        
                        id="name"
                        name="password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                    />


                </DialogContent>
                <DialogActions>
          
          <Button type="submit">Access website</Button>
        </DialogActions>
            </Dialog>
        </Fragment>
    );

}